<script context="module" lang="ts">
  export type Post = {
    data: { background: string; title: string };
    slug: string;
  }
</script>
<script lang="ts">
    import { onMount } from "svelte";
    import Styles from "./blog-list.module.css";
    import { getContentBackground } from "../utils/data";
  let list: HTMLElement;
  export let posts:Post[];
  const trackMouse = ({ pageX, pageY }: MouseEvent) => {
    list?.style.setProperty("--x", `${pageX - window.scrollX}px`);
    list?.style.setProperty("--y", `${pageY - window.scrollY}px`);
  };

  onMount(() => {
    list?.addEventListener("mousemove", trackMouse);
    return () => list.removeEventListener("mousemove", trackMouse);
  })
</script>

<ul bind:this={list} data-list class={Styles.List}>
  {#each posts as post}
  {#await getContentBackground(post.data.background, post.slug) then background }
    <li
      class={Styles.ListItem}
      style="--bg: url({background})"
    >
      <a href={`/blog/${post.slug}`}>{post.data.title}</a>
    </li>
  {/await}
  {/each}
</ul>
